<template>
  <div>
    <a-col>
      <a-input-search placeholder="Поиск по названию" style="width: 200px" @input="onSearch"/>
    </a-col>
    <a-table
      :columns="columns"
      :rowKey="record => record.Id"
      :dataSource="data"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
    <span slot="action" slot-scope="item">
      <a-button-group>
        <a-button type="primary">
          <router-link :to="{ name: 'products-edit', params: { productId: item.Id } }">
            Редактировать
          </router-link>
        </a-button>
          <a-popconfirm
            title="Действительно собираетесь удалить запись?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="destroy(item)"
          >
            <a-button type="danger">
              Удалить
            </a-button>
          </a-popconfirm>
      </a-button-group>
    </span>
    </a-table>
  </div>
</template>
<script>

import { sortQuery } from '../../helpers';

export default {
  data() {
    return {
      data: [],
      pagination: {},
      loading: false,
      page: 1,
      columns: [
        {
          title: 'Именование товара',
          dataIndex: 'Name',
          scopedSlots: { customRender: 'Name' },
          sorter: true,
        },
        {
          title: 'Категория',
          dataIndex: 'Category.Name',
          scopedSlots: { customRender: 'Category.Name' },
        },
        {
          title: 'Ед.Имерение',
          dataIndex: 'Measurement.Name',
          scopedSlots: { customRender: 'Measurement.Name' },
        },
        {
          title: 'Цена',
          dataIndex: 'Price',
          scopedSlots: { customRender: 'Price' },
          sorter: true,
        },
        {
          title: 'Действие',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.page = pagination.current;
      this.pagination = {
        ...this.pagination,
        current: pagination.current,
      };
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sort: sortQuery(sorter),
        ...filters,
      });
    },
    destroy(item) {
      this.loading = true;
      this.$api.deleteProduct(item, () => {
        this.$notification.success({
          message: `Продукт ${item.Name} удалён!`,
        });
        this.fetch();
        this.loading = false;
      });
    },
    fetch(params = {}) {
      this.loading = true;

      this.$api.getProducts({ page: this.page, ...params }, ({ data }) => {
        this.loading = false;
        this.data = data.data;
        this.pagination = {
          pageSize: data.meta.per_page,
          current: data.meta.current_page,
          total: data.meta.total,
        };
      }, () => {
        this.loading = false;
      });
    },
    barcode(event) {
      const { value } = event.target;
      const filters = {
        barcode: value,
      };
      const query = Object.keys(filters)
        .map((key) => filters[key] && filters[key].length && `filter[${key}]=${filters[key]}`)
        .join('&');
      this.$api.checkBarcode(query, ({ data }) => {
        if (data.data.length) {
          this.$router.push({
            name: 'products-edit',
            params: { productId: data.data[0].Id },
          });
        } else {
          this.$router.push({
            name: 'products-create',
            query: { barcode: value },
          });
        }
      });
    },
    onSearch(e) {
      const { value } = e.target;
      this.loading = true;
      this.$api.storageQuery(`filter[Name]=${value}`, ({ data }) => {
        this.loading = false;
        this.data = data.data;
        this.pagination = {
          pageSize: data.meta.per_page,
          current: data.meta.current_page,
          total: data.meta.total,
        };
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>
